@import '../../../../../../../vars.scss';

.root {
  height: 100%;
}

.grid {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gridItem {
  padding: 8px;
}

.imageAndVideo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.audioHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.pdfHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.fullHeight {
  height: 100%;
}
