.main {
  max-width: 1320px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
}

.content {
  margin-bottom: 100px;
}

.secondaryBtn {
  background-color: white !important;
  &:hover {
    background-color: white !important;
  }
}

.link {
  color: var(--color-typo-link);
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    color: var(--color-typo-link-hover);
  }
}

.offer {
  h3 {
    min-height: 55px;
  }
}

.desc {
  font-size: 16px;
  line-height: 150%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 32px;
}

.descList {
  margin-bottom: 14px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 4px;
      span {
        font-weight: normal;
      }
    }
  }
}

.topBlock {
  display: flex;
  align-items: center;
}

.toast {
  margin-left: auto;
}

.toastTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
}

.toastContent {
  margin-top: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

.toastContent a {
  color: black !important;
}

@media (max-width: 500px) {
  .topBlock {
    display: block;
  }

  .toast {
    margin-left: 0;
    margin-bottom: 40px;
  }
}
