@import '../../../../../../vars.scss';

.main {
  max-width: 1260px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto 150px;
  box-sizing: border-box;
}

.copyButton {
  margin-left: 16px;
}

.shareUrls {
  margin-top: 48px;
  display: 'flex';
  align-items: center;
  text-align: left;
}

.snackbar {
  margin-top: 24px;
}

.successStep {
  max-width: 800px;
  margin: 0 auto;
  .title {
    margin-bottom: 60px;
    margin-top: 30px;
    text-align: center;
    color: $brand;
  }
  .btn {
    width: 300px;
  }
  .bottom {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .btn {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .ImgPreview {
    height: 200px;
    object-fit: cover;
    width: 300px;
    border-radius: 4px;
  }
  .preview {
    h5 {
      text-align: center;
      font-weight: normal;
      color: $primary-text;
      font-size: 16px;
    }
  }
  .FilePreview {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .content {
    margin-top: 40px;
  }
  .smallCard {
  }
  .blockchain {
    margin-bottom: 40px;
    height: 50px;
    display: flex;
    width: max-content;
    justify-content: center;
    min-width: 200px;
  }
  .card {
    height: 300px;
    border: 1px solid $border;
    border-radius: 4px;
    position: relative;
    display: flex;
    .badge {
      position: absolute;
      top: 20px;
      left: 20px;
    }
    .img {
      height: 100%;
      width: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
    .mainContent {
      width: 50%;
      padding: $space-xl;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 $space-m;
        h4 {
          max-width: 70%;
        }
        > div {
          display: flex;
          align-items: center;
          color: $brand;
          .icon {
            margin-right: 5px;
          }
        }
      }
      h4 {
        color: $brand;
        margin: 0;
        font-size: 1.5em;
      }
      .text {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 0;
      }
    }

    .count {
      color: $brand;
      font-weight: 600;
      font-size: 1.5em;
    }
    @media (max-width: 1056px) {
      .code {
        width: 300px !important;
      }
    }
  }
  @media (max-width: 1056px) {
    .code {
      max-width: 100%;
      overflow-y: auto;
    }
  }
  @media (max-width: 600px) {
    .title {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .bottom {
      margin-top: 40px;
    }
    .card {
      height: auto;
      flex-direction: column;
      .img {
        width: 100%;
      }
      .main {
        width: 100%;
      }
    }
  }
}

.bottomCard {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid $border;
  display: flex;
  justify-content: space-between;
}
