.form {
  display: flex;
  width: 100%;

  .btn {
    width: 150px;
    margin-left: 14px;

    &.added {
      box-shadow: none !important;
      background-color: #29cb4c;
      cursor: unset;
    }
  }

  .input {
    flex: 1 1 auto;
  }
}

@media (max-width: 500px) {
  .form {
    flex-direction: column;

    .btn {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
