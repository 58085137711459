@import '../../../../../../vars.scss';

.main {
  max-width: 1260px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto 150px;
  box-sizing: border-box;
}

.error {
  color: red;
  flex-basis: 100%;
  height: 40px;
  text-align: center;
}

.loadingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

.reviewStep {
  .title {
    margin-bottom: 60px;
    margin-top: 30px;
    text-align: center;
    color: $brand;
  }
}

.reviewStep {
  .badge {
    margin-left: auto;
    margin-right: auto;
  }
  .btn {
    width: 300px;
  }
  .bottom {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .ImgPreview {
    height: 200px;
    object-fit: cover;
    width: 300px;
    border-radius: 4px;
  }
  .preview {
    h5 {
      text-align: center;
      font-weight: normal;
      color: $primary-text;
      font-size: 16px;
    }
  }
  .FilePreview {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
  }
  .content {
    margin-top: 60px;
  }
  .smallCard {
  }
  .blockchain {
    margin-bottom: 40px;
    height: 50px;
    display: flex;
    width: max-content;
    justify-content: center;
    min-width: 200px;
  }
  .card {
    min-height: 600px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border: 1px solid $border;
    border-radius: 4px;
    position: relative;
    .img {
      height: 60%;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .mainContent {
      padding: $space-xl;
      h4 {
        color: $brand;
        margin: 0 0 $space-m;
      }
    }
    .bottomBar {
      padding: $space-s $space-xl;
      border-top: 1px solid $border;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $brand;
      font-weight: 600;
      font-size: 1.5em;
    }

    @media (max-width: 1056px) {
      .code {
        width: 300px !important;
      }
    }
  }
  @media (max-width: 1056px) {
    .code {
      max-width: 100%;
      overflow-y: auto;
    }
  }
  @media (max-width: 600px) {
    .title {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .bottom {
      margin-top: 40px;
    }
    .card {
      min-height: 300px;
    }
  }
}
