@import '../../../../../../vars.scss';

.main {
  max-width: 1260px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto 150px;
  box-sizing: border-box;
}

.createStep {
  max-width: 800px;
  margin: 50px auto 100px;
  .title {
    margin-top: unset;
  }

  .files {
    display: flex;
    margin: 0 -8px;
  }
  .fileBlock {
    flex: 1 0 47.5%;
    margin: 0 8px;
  }
  .input,
  .choice,
  .quantity {
    margin-bottom: 40px;
    width: 100%;
  }
  .text {
    margin-bottom: 40px;
  }
  .bottom {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
  .label,
  .fileBlock h4 {
    color: $secondary-text;
    font-size: 18px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: normal;
  }
  @media (max-width: 750px) {
    .fileBlock {
      flex: 1 0 100%;
      margin-top: 20px;
    }
    .files {
      flex-direction: column;
    }
  }
}
