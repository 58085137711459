.main {
  max-width: 1320px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
}

.title {
  margin-top: unset;
}

.subtitle {
  color: var(--color-typo-secondary);
  margin-bottom: var(--space-xl);
}

.path {
  font-size: 1.75em;
  margin-bottom: var(--space-s);
}

.folderModal {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .folderSaveButton {
    margin-left: 8px;
  }
}
