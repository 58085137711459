@import '../../vars.scss';

.main {
  max-width: 1320px;
  padding: 50px 15px 0 15px;
  width: 100%;
  margin: 0 auto 50px auto;
  box-sizing: border-box;
  background-image: url(./assets/bg2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.link {
  text-decoration: none;
}

.top {
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailCaptureModal {
  padding: 24px;
}

.closeHolder {
  display: flex;
  justify-content: flex-end;

  .close {
    cursor: pointer;
  }
}

.form {
  max-width: 500px;
  width: 100%;
  display: flex;
  margin: 0 auto 32px;
}

.title {
  margin-top: 20px;
  font-family: 'Lato';
  margin-left: -10px;

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;
    background: linear-gradient(180deg, #000000 -4.76%, #a9a9a9 89.51%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 90px;
    line-height: 120%;
    background: linear-gradient(180deg, #31035f 0%, #9a8abc 79.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 60px;
    margin-left: 50px;
  }
}

.text {
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  margin-bottom: 50px;
  max-width: 614px;
}

.subtitle {
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  color: $secondary-text;
  margin: 0 0 30px;
}

.content {
  margin-top: 120px;
  display: flex;
  justify-content: center;

  .offer {
    width: calc(((100% - (36px * 2)) / 3));
    margin-right: 36px;

    &:nth-child(3) {
      margin-right: 0;
    }
  }

  @media (max-width: 1024px) {
    display: block;

    .offer {
      width: 100%;
      margin-right: 0;
      z-index: 0;
    }
  }

  .desc {
    font-size: 16px;
    line-height: 150%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 32px;
  }

  .descList {
    margin-bottom: 14px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 4px;

        span {
          font-weight: normal;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .content {
    margin-top: 60px;
  }
  .title {
    h3 {
      font-size: 40px;
    }

    h2 {
      font-size: 70px;
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 500px) {
  .subtitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .text {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .title {
    text-align: center;

    h3 {
      margin-left: 0;
    }

    h2 {
      margin-left: 0;
    }
  }
}

@media (max-width: 400px) {
  .content {
    margin-top: 20px;
  }
  .form {
    flex-direction: column;
  }
  .title {
    h3 {
      font-size: 30px;
    }

    h2 {
      font-size: 60px;
      margin-bottom: 30px;
    }
  }
}
