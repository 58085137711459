$space-xl: var(--space-xl);
$space-2xl: var(--space-2xl);
$space-3xl: var(--space-3xl);
$space-m: var(--space-m);
$space-s: var(--space-s);

$brand: var(--color-bg-brand);

$primary-text: var(--color-typo-primary);
$secondary-text: var(--color-typo-secondary);
$link: var(--color-typo-link);
$link-hover: var(--color-typo-link-hover);
$alert-text: var(--color-typo-alert);
$typo-placeholder: var(--color-control-typo-placeholder);
$typo-link-minor: var(--color-typo-link-minor);

$border: var(--color-control-bg-border-default-hover);

$color-base-base: #eb5757;

$purple: #8159d9;
$brandLight: #f0ecf8;
$brand-4: #5e36b7;
$brand-text: #2c0c72;
