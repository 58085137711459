.whatToDoModalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.65);
}

.whatToDoModal {
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: #fff;
  top: 50%;
  left: 50%;
  width: 25rem;
  //height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 13;
  box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
}

.whatToDoHeaderContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
  box-sizing: border-box;
  background-color: #f0ecf8;
}

.whatToDoHeaderTitle {
  font-family: Segoe UI, 'Open Sans', sans-serif;
  font-weight: 300;
  color: rgba(0, 5, 51, 0.8);
  font-size: 18px;
}

.whatToDoHeaderClose {
  border: none;
  cursor: pointer;
}

.titles p {
  //color: $primary-text;
  //font-size: 1.25em;
  font-family: Segoe UI, 'Open Sans', sans-serif;
  font-weight: 300;
  //margin: 0 0 $space-s;
  @media (max-width: 600px) {
    font-size: 1.2em;
    margin: 0 0 10px;
  }
  @media (max-width: 450px) {
    font-size: 1em;
    margin: 0 0 10px;
  }
}

.confirmationLogo {
  height: 78px;
  width: 65px;
  text-align: center;
  margin-bottom: 1rem;
}

.types {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
  margin-bottom: 0.25rem;

  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.copied {
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  background-color: #42974a;
  //margin: 0.25rem auto 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
  margin-bottom: 1rem;
}

.btnBock {
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  background-color: #8159d9;
  //margin: 0.25rem auto 1rem;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: all 0.3s;
  margin-bottom: 1rem;

  &:hover {
    background-color: #2c0c72;
  }
}

h5 {
  color: #fff;
  font-size: 0.85rem;
  margin: 0 auto;
  font-family: Roboto, 'Open Sans', sans-serif;
  font-weight: 500;
}
