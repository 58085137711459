.tableWrapper {
  margin: 40px 29px 47px 41px;
}

.noFilesText {
  margin: 40px auto;
  font-family: Segoe UI, 'Open Sans', sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: #002033;
}
