.main {
  max-width: 1320px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
}

.title {
  font-size: 3em;
  color: var(--color-typo-primary);
  border-bottom: 1px solid var(--color-typo-ghost);
  padding-bottom: var(--space-xl);
  margin-bottom: var(--space-3xl);
  @media (max-width: 640px) {
    font-size: 40px;
  }
}

.subtitle {
  color: var(--color-typo-secondary);
  margin-bottom: var(--space-3xl);
}
