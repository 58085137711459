@import '../../vars.scss';

.main {
  max-width: 1320px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto 150px;
  box-sizing: border-box;
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 40px;
  align-items: center;
  .left {
    .leftTop {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 24px;
      svg {
        margin-right: 12px;
      }
    }
  }
  .right {
    max-width: 250px;
    width: 100%;
  }
  .select {
    max-width: 250px;
    width: 100%;
    flex: none;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    .select {
      margin-top: 20px;
    }
    .left {
      .leftTop {
        font-size: 20px;
      }
    }
  }
}

.list {
  h2 {
    color: $brand;
  }
}
