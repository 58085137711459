@import '../../vars';

.error {
  color: $alert-text;
  font-size: 14px;
  line-height: 100%;
  padding-left: 10px;
  margin-top: 4px;
}

.clearable {
  position: absolute;
  right: 10px;
  bottom: 31%;
  z-index: 2;
  color: $typo-placeholder;
  cursor: pointer;
}

.wrap {
  width: 0px;
  padding: 0px;
  transition: all 0.2s;
  opacity: 0;
  position: relative;
  margin-right: -20px;
  .innerInput {
    padding: 0;
  }
  .innerInput,
  input {
    width: 0;
    min-width: 0;
    transition: all 0.2s;
  }
}

.showWrap {
  width: 250px;
  opacity: 1;
  .innerInput,
  input {
    width: 250px;
    min-width: 80px;
  }
  .innerInput {
    padding: 0 var(--input-space);
  }
}

.search {
  transition: opacity 0.3s;
  z-index: 10;
  cursor: pointer;
  color: #000 !important;
}

.hideSearch {
  opacity: 0;
}

.clearable {
  z-index: 20;
}

.input {
  display: flex;
  position: relative;
  align-items: center;
  margin-right: 55px;
}
