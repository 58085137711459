.table {
  width: 100%;
  border-collapse: collapse;
  border: none;

  th {
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: #002033;
    text-align: left;
    padding: 0 8px 20px 8px;
  }

  tr {
    td {
      border-top: 1px solid rgba(0, 65, 102, 0.2);
      font-size: 14px;
      line-height: 150%;
      color: #002033;
      padding: 8px;

      &.removeCell {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
          color: rgba(0, 5, 51, 0.6);
          cursor: pointer;
        }
      }
    }

    &:hover {
      td {
        background-color: rgba(0, 32, 51, 0.05);
      }
    }
  }
}
