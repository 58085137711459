@import '../../../vars.scss';

.main {
  max-width: 1260px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto 150px;
  box-sizing: border-box;
}

.img {
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.left {
  h2 {
    color: $brand;
  }
  .bottom {
    margin-top: 30px;
    justify-content: space-between;
    display: flex;
    font-size: 20px;
    color: $secondary-text;
  }
}

.table {
  max-height: 70vh;
  overflow-x: hidden;
  border: 1px solid $border;
}

@media (max-width: 1340px) {
  .img {
    height: 350px;
  }
}

@media (max-width: 900px) {
  .img {
    height: 300px;
  }
}

@media (max-width: 500px) {
  .left {
    .bottom {
      font-size: 18px;
    }
  }
}

@media (max-width: 400px) {
  .img {
    height: 250px;
  }
}
