@import '../../vars.scss';

.main {
  max-width: 1320px;
  padding: 50px 15px 0 15px;
  width: 100%;
  margin: 0 auto 50px auto;
  box-sizing: border-box;
  background-image: url(./assets/bg2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

.top {
  max-width: 700px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  max-width: 700px;
  margin: 0 auto;
}

.subtitle {
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  margin: 0 0 60px;
  color: $primary-text;
}

.title {
  font-family: 'Lato';
  margin-left: -30px;
  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;
    background: linear-gradient(180deg, #000000 -4.76%, #a9a9a9 89.51%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
  }
  h2 {
    font-weight: bold;
    font-size: 90px;
    line-height: 100%;
    background: linear-gradient(180deg, #31035f 0%, #9a8abc 79.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 0 100px;
    margin-left: 40px;
  }
}

.form {
  max-width: 500px;
  width: 100%;
  display: flex;
  margin: 0 auto 32px;
  .btn {
    margin-left: 14px;
  }
  .input {
    flex: 1 1 auto;
  }
}

.btn {
  display: flex;
  margin: 0 auto;
}

.textBlock {
  border-left: 14px solid #dadada;
  border-radius: 4px;
  padding-left: 18px;
  margin-bottom: 32px;
  p {
    font-size: 18px;
    line-height: 150%;
    color: $primary-text;
    margin: 0;
  }
}

.secondaryText {
  font-size: 18px;
  line-height: 150%;
  color: $primary-text;
  margin: 0 0 32px;
}

.bg {
  position: fixed;
  top: 100px;
  right: 0;
  z-index: -1;
}

@media (max-width: 600px) {
  .title {
    h3 {
      font-size: 40px;
    }
    h2 {
      font-size: 70px;
      margin-bottom: 40px;
    }
  }
  .subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .textBlock p {
    font-size: 18px;
  }
  .secondaryText {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .title {
    h3 {
      margin-left: 0;
    }
    h2 {
      margin-left: 0;
    }
  }
}

@media (max-width: 400px) {
  .form {
    flex-direction: column;
    .btn {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .title {
    h3 {
      font-size: 30px;
    }
    h2 {
      font-size: 60px;
      margin-bottom: 30px;
    }
  }
  .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
  .textBlock p {
    font-size: 16px;
  }
  .secondaryText {
    font-size: 14px;
  }
}
