.accessCreatedModalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.65);
}

.accessCreatedModal {
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: #fff;
  top: 50%;
  left: 50%;
  width: 25rem;
  //height: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 13;
  box-shadow: 0px 4px 43px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
}

.accessCreatedHeaderContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  border-radius: 0.25rem 0.25rem 0 0;
  box-sizing: border-box;
  background-color: #f0ecf8;
}

.accessCreatedHeaderTitle {
  font-family: Segoe UI, 'Open Sans', sans-serif;
  font-weight: 300;
  color: rgba(0, 5, 51, 0.8);
  font-size: 18px;
}

.accessCreatedHeaderClose {
  border: none;
  cursor: pointer;
}

.titles {
  margin: 1rem 0 16px;
}

.titles h3 {
  font-size: 1.5em;
  @media (max-width: 600px) {
    font-size: 1.2em;
    margin: 0 0 10px;
  }
  @media (max-width: 450px) {
    font-size: 1em;
    margin: 0 0 10px;
  }
}

.titles p {
  //color: $primary-text;
  font-weight: 300;
  font-family: Segoe UI, 'Open Sans', sans-serif;
  margin: 0 auto;
  text-align: center;
  width: 90%;
}

.confirmationLogo {
  height: 78px;
  width: 65px;
  text-align: center;
}

.link {
  font-family: Segoe UI, 'Open Sans', sans-serif;
  font-weight: 300;
  //color: $link;
  cursor: pointer;
  transition: all 0.3s;
  color: #2c0c72;
  //&:hover {
  //  color: $link-hover;
  //}
}

.types {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  //padding: 1rem;
  box-sizing: border-box;
  @media (max-width: 400px) {
    flex-direction: column;
  }
}

.type {
  margin: 4px;
  height: auto;

  h4 {
    font-size: 1.2em;
    text-align: center;
    margin: 0 0 4px;
    //color: $primary-text;
    @media (max-width: 600px) {
      font-size: 1em;
    }
  }

  .copied {
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    display: flex;
    background-color: #42974a;
    margin: 0.25rem auto 1rem;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btnBock {
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    display: flex;
    background-color: #8159d9;
    margin: 0.25rem auto 1rem;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: #2c0c72;
    }
  }

  h5 {
    font-family: Roboto, 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 0.85rem;
    color: white;
    text-align: center;
    margin: 0 auto;
  }
}
