.unsavedPopupWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 100;
  display: flex;
  align-items: center;

  .unsavedPopup {
    background: #fff;
    margin: 0 auto;
    width: 275px;
    font-size: 14px;
    padding: 14px 8px 20px 8px;
    text-align: center;
    border-radius: 4px;

    .buttons {
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
    }
  }
}
