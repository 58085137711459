@import '../../vars.scss';

.wrap {
  display: flex;
  align-items: center;
  margin-top: 74px;
  margin-bottom: 80px;
  img {
    margin-right: 20px;
  }
}

.title {
  h2 {
    font-family: Space Grotesk;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 150%;
    position: relative;
    margin: 0;
  }

  h5 {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: $secondary-text;
    margin: 0;
  }
}

@media (max-width: 600px) {
  .wrap {
    flex-direction: column;
    align-items: flex-start;
    img {
      margin-bottom: 10px;
    }
    margin-bottom: 40px;
    margin-top: 60px;
  }
}
