@import '../../vars';

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.error {
  animation-name: fadein;
  animation-duration: 0.25s;
  color: $alert-text;
  font-size: 14px;
  line-height: 100%;
  padding-left: 10px;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.success {
  animation-name: fadein;
  animation-duration: 0.25s;
  color: #127252;
  font-size: 14px;
  line-height: 100%;
  padding-left: 10px;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

.clearable {
  position: absolute;
  right: 10px;
  bottom: 31%;
  z-index: 2;
  color: $typo-placeholder;
  cursor: pointer;
}

.input {
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 400px;

  > div {
    width: 100%;
    position: relative;

    > div {
      width: 100%;
    }
  }
}

.employeeMainInput {
  flex: 1 1 auto;
}

.employeeMainInput {
  flex: 1 1 auto;
}

.input {
  label {
    color: $secondary-text;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 8px;
    @media (max-width: 700px) {
      font-size: 16px;
      line-height: 150%;
    }
    @media (max-width: 450px) {
      font-size: 14px;
      line-height: 150%;
    }
  }
}

@media (max-width: 500px) {
  .input {
    width: calc(90vw);
  }

  .error,
  .success {
    margin-left: 0;
    text-align: center;
  }
}
