.wrapper {
  position: relative;
  cursor: pointer;
  ul {
    position: absolute;
    top: 45px;
    background: white;
    width: 100%;
    min-width: 150px;
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 3px 0;
    border: 1px solid rgba(0, 65, 102, 0.2);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 32, 51, 0.04),
      0px 8px 24px rgba(0, 32, 51, 0.12);
    border-radius: 4px;
    z-index: 10000;
    li {
      padding: 8px 12px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
