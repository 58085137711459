/* Базовые цвета, от которых выстраивается вся палитра */ /* Базовый цвет содержимого, от которого выстраиваются цвета текста, иконок, ... */ /* Базовый цвет поверхностей */ /* Проектный цвет, от которого выстраивают акцентные состояния */ /* Тонирующий цвет, от которого выстраиватся бордеры, оверлей под модальными окнами, ... */ /* Цвет ссылок и контролов, от которого выстраиваются все их состояния */ /* Цвет системнный */ /* Цвет тени */ /* Цвет успеха */ /* Цвет ошибки */ /* Цвет предупреждения */ /* Цвет осторожности */ /* Цвет нейтральный */

/* stylelint-disable */
.Theme_color_gpnDefault {
  /* //////////////////////////////
  // Цвета фонов /////////////// */
  --color-bg-default: rgb(255, 255, 255); /* Цвет дефолта */
  --color-bg-secondary: rgb(236, 241, 244); /* Цвет дефолта */
  --color-bg-brand: #5e36b7; /* Цвет фона брендированый */
  --color-bg-link: #8159d9; /* Цвет фона ссылки */
  --color-bg-border: rgba(0, 65, 102, 0.2); /* Цвет границ */
  --color-bg-stripe: rgba(
    0,
    32,
    51,
    0.05
  ); /* Цвет зебры (например, при окрашивании фона строк таблицы через одну) */

  --color-bg-ghost: rgba(0, 32, 51, 0.08); /* Цвет затенения */
  --color-bg-tone: rgba(0, 32, 51, 0.85); /* Цвет паранджи */
  --color-bg-soft: rgba(255, 255, 255, 0.9); /* Цвет молока */
  --color-bg-system: rgb(206, 216, 222); /* Цвет фона системнный */
  --color-bg-normal: rgb(86, 185, 242); /* Цвет фона нейтральный */
  --color-bg-success: rgb(34, 195, 142); /* Цвет фона успеха */
  --color-bg-caution: rgb(242, 201, 76); /* Цвет фона осторожности */
  --color-bg-warning: rgb(243, 139, 0); /* Цвет фона предупреждения */
  --color-bg-alert: rgb(235, 87, 87); /* Цвет фона ошибки */
  --color-bg-critical: rgb(51, 0, 15); /* Цвет фона системнный */

  /* //////////////////////////////
  // Цвета текста ////////////// */
  --color-typo-primary: rgb(0, 32, 51); /* Цвет основного текста */
  --color-typo-secondary: rgba(
    0,
    32,
    51,
    0.6
  ); /* Цвет второстепенного текста */
  --color-typo-ghost: rgba(0, 32, 51, 0.3); /* Цвет дополнительного текста */
  --color-typo-brand: #5e36b7; /* Цвет брендового текста */
  --color-typo-system: rgb(132, 167, 189); /* Цвет фона системнный */
  --color-typo-normal: rgb(51, 180, 255); /* Цвет фона нейтральный */
  --color-typo-success: rgb(9, 179, 123); /* Цвет текста успеха */
  --color-typo-caution: rgb(255, 161, 10); /* Цвет текста осторожности */

  --color-typo-warning: rgb(232, 117, 2); /* Цвет текста предупреждения */

  --color-typo-alert: rgb(235, 51, 51); /* Цвет текста ошибки */
  --color-typo-critical: rgb(51, 0, 15); /* Цвет фона системнный */
  --color-typo-link: #8159d9; /* Цвет основной ссылки */
  --color-typo-link-minor: rgb(28, 86, 130); /* Цвет второстепенной ссылки */
  --color-typo-link-hover: #9167ee; /* Цвет ховера ссылки */

  /* //////////////////////////////
  // Цвета скроллбара ////////////// */
  --color-scroll-bg: rgba(0, 66, 105, 0.06);
  --color-scroll-thumb: rgba(0, 66, 105, 0.24);
  --color-scroll-thumb-hover: rgba(0, 66, 105, 0.32);

  /* /////////////////////////////
  // Цвета контролов /////////// */

  /* Default */
  --color-control-bg-default: rgb(255, 255, 255); /* Цвет фона контрола */
  --color-control-typo-default: rgb(
    0,
    32,
    51
  ); /* Цвет основного текста контрола */
  --color-control-typo-placeholder: rgba(
    0,
    32,
    51,
    0.35
  ); /* Цвет плейсхолдера контрола */
  --color-control-bg-border-default: #bfa7f3; /* Цвет фона бордеров котрола */

  --color-control-bg-border-default-hover: #bfa7f3; /* Цвет фона бордеров контрола по ховеру */

  --color-control-bg-border-focus: #9167ee; /* Цвет аутлайна контролов при фокусе */ /* TODO заменить на правило целиком */
  --color-control-bg-focus: #9167ee; /* Цвет тени в состонии фокуса */
  --color-control-bg-active: #9167ee; /* Цвет тени в состонии нажатия  */

  /* Primary */
  --color-control-bg-primary: #5e36b7; /* Цвет фона активного контрола */
  --color-control-bg-primary-hover: #8159d9; /* Цвет фона активного контрола по ховеру */

  --color-control-typo-primary: rgb(255, 255, 255); /* Цвет акцентного текста */
  --color-control-typo-primary-hover: rgb(
    255,
    255,
    255
  ); /* Цвет акцентного текста */

  /* Secondary */
  --color-control-bg-secondary: rgba(
    255,
    255,
    255,
    0
  ); /* Цвет фона бордеров котрола */
  --color-control-bg-border-secondary: #5e36b7; /* Цвет фона бордеров котрола */

  --color-control-bg-border-secondary-hover: #8159d9; /* Цвет фона бордеров контрола по ховеру */

  --color-control-typo-secondary: #5e36b7; /* Цвет второстепеного текста контрола */

  --color-control-typo-secondary-hover: #8159d9; /* Цвет акцентного текста */

  /* Ghost */
  --color-control-bg-ghost: rgba(
    0,
    66,
    105,
    0.07
  ); /* Цвет фона дополнительного контрола */

  --color-control-bg-ghost-hover: rgba(
    0,
    66,
    105,
    0.05
  ); /* Цвет фона дополнительного контрола по ховеру */

  --color-control-typo-ghost: rgba(
    0,
    57,
    92,
    0.8
  ); /* Цвет дополнительного текста контрола */

  --color-control-typo-ghost-hover: rgba(
    0,
    57,
    92,
    0.75
  ); /* Цвет акцентного текста */

  /* Clear */
  --color-control-bg-clear: rgba(
    0,
    66,
    105,
    0
  ); /* Цвет фона чистого контрола */
  --color-control-bg-clear-hover: rgba(
    0,
    66,
    105,
    0.05
  ); /* Цвет фона чистого контрола по ховеру */

  --color-control-typo-clear: rgba(0, 57, 92, 0.8); /* Цвет акцентного текста */

  --color-control-typo-clear-hover: rgba(
    0,
    57,
    92,
    0.75
  ); /* Цвет акцентного текста */

  /* Disable */
  --color-control-bg-disable: rgba(
    0,
    66,
    105,
    0.07
  ); /* Цвет фона неактивного контрола */
  --color-control-bg-border-disable: rgba(
    0,
    66,
    105,
    0
  ); /* Цвет фона бордеров неактивного контрола */

  --color-control-typo-disable: rgba(
    0,
    32,
    51,
    0.26
  ); /* Цвет основного текста неактивного контрола */

  /* /////////////////////////////
  // Цвета тени /////////// */
  --color-shadow-group-1: rgba(0, 32, 51, 0.02);
  --color-shadow-group-2: rgba(0, 32, 51, 0.16);
  --color-shadow-layer-1: rgba(0, 32, 51, 0.04);
  --color-shadow-layer-2: rgba(0, 32, 51, 0.12);
  --color-shadow-modal-1: rgba(0, 32, 51, 0.04);
  --color-shadow-modal-2: rgba(0, 32, 51, 0.12);
}
/* stylelint-enable */
