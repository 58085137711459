@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-list {
  margin: 0 -15px !important;
}

.slick-slide > div {
  padding: 0 15px !important;
}

.slick-track {
  margin-left: 0 !important;
  display: flex !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// .TextField-InputContainer {
//   //TODO remove
//   border: none !important;
// }

body .Modal-Window {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1400px) {
  body .Modal {
    padding: 20px;
  }
}

@media (max-width: 1200px) {
  body .Modal {
    padding: 10px;
  }
}

@media (max-width: 600px) {
  body .Modal {
    padding: 0px;
  }
}

@media (max-width: 800px) {
  body .Modal-Window {
    max-height: 98%;
    max-width: 98%;
  }
}

@media (max-width: 400px) {
  body .Modal-Window {
    min-width: 100%;
  }
}

body .Modal {
  z-index: 100;
}
