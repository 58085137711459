.main {
  max-width: 1320px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
}

.title {
  font-size: 3em;
  color: var(--color-typo-primary);
  border-bottom: 1px solid var(--color-typo-ghost);
  padding-bottom: var(--space-xl);
  margin-bottom: var(--space-3xl);
  @media (max-width: 640px) {
    font-size: 40px;
  }
}

.subtitle {
  color: var(--color-typo-secondary);
  margin-bottom: var(--space-3xl);
}

.mainText {
  font-size: 1.2em;
  margin-bottom: var(--space-3xl);
}

.card {
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  background: linear-gradient(to right, #2c3e50, #3498db);
  padding: 3em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  h2 {
    text-align: center;
    color: white;
  }
  @media (max-width: 700px) {
    height: 200px;
    box-sizing: border-box;
  }
}
