@import '../../vars.scss';

.header {
  position: fixed;
  z-index: 10;
  padding: 0 5px;
  height: 80px !important;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-bottom: 0 !important;

  .burger {
    display: none;
    margin-right: 20px;
    @media (max-width: 1024px) {
      display: block;
    }
  }

  .light {
    color: rgba(0, 5, 51, 0.6);
    font-size: 24px;
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .link {
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    color: #000;
  }

  .connectButton {
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    color: var(--color-bg-brand);
    //border-bottom: 1px solid #8159d9;
    cursor: pointer;
  }

  .activeLink {
    color: $brand;
    border-bottom: 1px solid $purple;
  }

  .rightSide {
    display: flex;
    align-items: center;

    > * {
      margin-right: 55px;

      &:last-of-type {
        margin-right: 45px;
      }
    }

    .twitter {
      color: #1d9bf0;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .connected {
    position: relative;

    .connectedTooltip {
      z-index: 10;
      position: absolute;
      text-align: center;
      width: 11.5rem;
      padding: 0.5rem;
      font-size: 0.8rem;
      top: 1rem;
      color: #fff;
      right: 0.5rem;
      border-radius: 10px 0 10px 10px;
      background-color: rgba(94, 54, 183, 0.7);
      display: none;
    }
  }

  .connected:hover {
    .connectedTooltip {
      animation-name: fadein;
      animation-duration: 0.25s;
      display: inline;
    }
  }
}

@media (max-width: 640px) {
  .search {
    display: none !important;
  }

  .connectButton {
    margin-right: 0 !important;
  }

  .mobileWarningModal {
    z-index: 10;
    width: 90%;
    height: auto;

    .warningModalContent {
      padding: 1.4rem;

      .warningModalActions {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 450px) {
  .search {
    display: none !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    height: 120px !important;
    position: fixed;

    img {
      position: absolute;
      left: 45%;
      top: 10px;
      height: 40px;
    }

    .link {
      font-size: 18px;
    }

    > div:last-of-type {
      justify-content: center;
      margin: 10px;
    }
  }
}

.connected {
  width: 15px;
  height: 15px;
  background-color: #28cb4c;
  border-radius: 100%;
  margin-right: 9px;
}

.mobileLogo {
  left: unset !important;
}
