@import './vars';

.app {
  position: relative;
  min-height: 100vh;

  &.withOpenSidebar {
    max-height: 100vh;
    overflow: hidden;
  }

  .backgroundLogo {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -2;
    width: 122px;
    height: 156px;
    background-image: url('./assets/imgs/background-logo.svg');
    background-repeat: no-repeat;
  }

  .linksWrapper {
    position: fixed;
    left: 22px;

    color: $purple;

    > a {
      color: $purple;
      display: block;
    }

    .developerDocs {
      text-decoration: none;
    }

    .privacyPolicy {
      font-size: 10px;
    }

    > * {
      margin-bottom: 38px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mobileFooter {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 5;
}

.wrap {
  padding-top: 100px;
  overflow-y: auto;
  width: calc(100% - 280px);
  margin-left: 280px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-left: 0;
  }
}

.minter {
  height: auto;
}

.globalError {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.minterWrap {
  max-width: 1260px;
  padding: 0 15px;
  width: 100%;
  margin: 50px auto;
  box-sizing: border-box;
}

body {
  .Modal {
    padding: 10px;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  h2 {
    font-size: 2em;
    color: var(--color-typo-primary);
    @media (max-width: 450px) {
      font-size: 1.7em;
    }
    @media (max-width: 380px) {
      font-size: 1.5em;
    }
  }

  h3 {
    font-size: 1.5em;
    color: var(--color-typo-primary);
  }

  h4 {
    font-size: 1.2em;
    color: var(--color-typo-primary);
  }
}
